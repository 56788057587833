.nav_con{
    width: 100%;
    height: 70px; 
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.support_col{
    padding-right: 0px;
    padding-left: 0px;
}
.support_row{
    margin-right: 0px;
    margin-left: 0px;
}
.nav_heading{
    font-size: 25px;
    font-weight: bold;
}
.con1{
    padding: 20px;
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.con2{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bell_icon{
    margin-right: 10px;
}
.profile_icon{
    margin-left: 10px;
    margin-right: 20px;
}
.hamburger{
    cursor: pointer;
}
.line{
    margin-bottom: 5px;
    width: 40px;
    height: 5px;
    background-color:#00278a;
}
@media (min-width: 992px) {
    .hamburger{
        display: none;
    }
}