.Nav_side{
    z-index: 999999;
    transition: all 0.2s;
    position: fixed;
    height:105vh;
    width: 260px;
    background-color: #00278a;
    overflow-y: scroll;
}
.side_logo{
    padding: 30px;
}
.Nav_links_con{
    display: flex;
}
.Nav_links{
    padding-left: 30px;
    margin-bottom: 30px;
    padding-right: 20px;
}
.Nav_heading{
    color: white;
    margin-left: 10px;
    font-size: 15px;
    letter-spacing: 1px;
}
@media (max-width: 575.98px) {
    .Nav_side{
        width: 0px;
    }
}
@media (max-width: 992px){
    .Nav_side{
        width: 0px;
    }
}