.App {
	text-align: center;
}

.teacher_unverified_container {
	background-color: #E1E1E1;
	min-height: 100vh;
	padding-bottom: 16px;
}

.teacher_dashboard_verify_ques_pending_container {
	display: flex; 
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    grid-gap: 10px;
    gap: 10px;
    background: #EAF3FF;
    border-radius: 101px;
    width: max-content;
    margin: 0.5rem auto 0.2rem auto;
    text-align: center;
}

.teacher_dashboard_verify_ques_pending_text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 27px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.02em;
	color: #012C63;
}


.teacher_unverified_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.teacher_unverified_header-first {
    display: flex;
    align-items: center;
    flex: 0 0 70%;
    width: 70%;
}

.teacher_unverified_header-second {
    display: flex;
    align-items: center;
	justify-content: flex-end;
    flex: 0 0 30%;
    width: 30%;
}

@media screen and (max-width: 600px) {
    .teacher_unverified_header-first,
    .teacher_unverified_header-second {
        width: 100%;
        flex: auto;
    }

	.teacher_unverified_header-second {
		margin-top: 16px;
		justify-content: flex-start;
	}
}

.teacher_unverified_questions_container {
	margin: 4rem 6rem;
}

.teacher_unverified_questions_container.t-loading {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 91px);
	width: 100%;
}

.teacher_unverified_questions_container.not-found {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 91px);
	width: 100%;

	font-size: 26px;
	font-weight: 500;
	color: #1F3061;
}

.teacher_header_subject {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;	
	letter-spacing: 0.04em;
	color: #000000;
	margin-left: 1.5rem;
	width: calc(100% - 17px);
}

.teacher_header_subject-goback {
	width: 17px;
}

.teacher_header_unverified {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	color: #201F1F;
	margin-left: 2rem;
}

.d-flex {
	display: flex !important;
}

.align-items-center {
	align-items: center !important;
}

.justify-items-center {
	justify-content: center !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.teacher_unverified_header_count_text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
}

.teacher_unverified_header_count_container {
	background-color: #1F3061;
    border-radius: 50%;
	min-height: 34px;
	min-width: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.unverified_subject_cards_container {
	margin-top: 2rem;
}

.unverified_subject_cards_empty {
	height: 70vh;
	padding-top: 30px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	background: #fff;
}

.congratulations_gif {
	width: 270px;
	height: auto;
}

.congratulations_text {
	width: 270px;
	padding-top: 20px;
	font-size: 22px;
	font-weight: 300;
	text-align: center;
	color: #272323;
}

.unverified_subject_cards {
	padding: 20px;
	gap: 10.91px;
	background: #FFFFFF;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.unverified_subject_cards_subject_text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 17.84px;
	line-height: 21px;	
	letter-spacing: -0.01em;
	color: #000000;
}

.unverified_subject_cards_count_container {
	background-color: #1F3061;
    border-radius: 50%;
	height: 52px;
	width: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.unverified_subject_cards_count_text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	color: #FFFFFF;
}

.unverified_chapter_count {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background: #F2F6FF;
	border-radius: 50%;
	font-weight: 500;
	font-size: 24px;
	color: #1F3061;
	margin-right: 32px;
}

.unverified_subject_cards-accordion-root:before {
	display: none;
}

.unverified_subject_cards-accordion-expanded {
	margin-top: 6px;
}

.unverified_subject_cards-accordion-content.Mui-expanded.unverified_subject_cards-accordion-expanded {
	margin: 12px 0;
}

.teacher_unverified_container_question {
	background-color: #E1E1E1;
	padding-bottom: 20px;
}

.question-solution-container {
	width: 63%;
}

.right-panel {
	width: 35%;
}

.questions-details-container {
	margin: 2rem 0rem;
	background-color: #ffffff;
	align-items: flex-start;
	padding: 20px;
	gap: 14px;
	border-radius: 8px;
}

.solutions-container {
	margin: 2rem 0rem;
	background-color: #ffffff;
	align-items: flex-start;
	padding: 20px;
	gap: 14px;
	border-radius: 8px;
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 18px;
}

.buttons-container-skip-approve {
	margin: 2rem 0rem;
	background-color: #ffffff;
	padding: 18px 40px;
	gap: 14px;
	border-radius: 8px;
	font-family: 'Montserrat';
	display: flex;
	justify-content: space-between;
}

.buttons-container-class {
	align-self: center;
	display: flex;
}

.questions_container {
	display: flex;
	justify-content: space-between;
}

.ques-line-1 {
	justify-content: space-between;
}

.question-num {
	font-family: 'Montserrat';
	color: #595959;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.015em;
}

.q-id {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.015em;
	color: #595959;
	font-family: 'Montserrat';
	padding: 2px 10px;
	gap: 2px;
	background: #E7E7E7;
	border-radius: 16px;
}

.question {
	margin: 10px 0px;
	font-family: 'Montserrat';
	padding: 8px 0px 16px 1px;
	gap: 10px;
	text-align: justify;
	font-weight: 500;
	font-size: 18px;
	color: #5B5B5B;
}

.option {
	background: #FFFFFF;
	border: 1px solid #DFDFDF;
	border-radius: 8px;
	font-family: 'Montserrat';
	margin: 10px 0px;
	padding: 12px;
	gap: 12px;
	font-weight: 500;
	font-size: 18px;
	color: #5B5B5B;
	text-align: justify;
}

.correct-option {
	background: #E3FFDA;
	border: 1px solid #2EB100;
	border-radius: 8px;
	font-family: 'Montserrat';
	margin: 10px 0px;
	padding: 12px;
	gap: 12px;
	font-weight: 500;
	font-size: 18px;
	color: #5B5B5B;
	text-align: justify;
}

.correct-option .option-num {
	font-weight: 600;
}

.solution-heading {
	color: #595959;
}

.option-correct>.option_num {
	color: #000000;
}

.option>.option-num {
	color: #595959;
}

.skip-container {
	align-self: center;
}

.skip-anchor,
.skip-anchor:hover,
.skip-anchor:visited,
.skip-anchor:focus {
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.015em;
	color: #2B3789;
	cursor: pointer;
	text-decoration: none;
}

.questions-reject-button,
.questions-reject-button:hover,
.questions-reject-button:focus,
.questions-reject-button:visited {
	padding: 8px 28px;
	gap: 10px;
	background: #FFFFFF;
	border: 1px solid #2B3789;
	border-radius: 8px;
	margin-right: 10px;
	color: #2B3789;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
}

.questions-approve-btn,
.questions-approve-btn:hover,
.questions-approve-btn:focus,
.questions-approve-btn:visited {
	padding: 8px 28px;
	gap: 10px;
	background: #2B3789;
	border: 1px solid #2B3789;
	border-radius: 8px;
	margin-left: 10px;
	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
}

.questions-btn-block,
.questions-btn-block:hover,
.questions-btn-block:focus,
.questions-btn-block:visited {
	font-family: 'Montserrat';
	padding: 8px 28px;
	gap: 10px;
	background: #2B3789;
	border: 1px solid #2B3789;
	border-radius: 8px;
	margin-left: 10px;
	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
}

.questions-btn-block.reject {
	height: 40px;
	width: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
}

.questions-btn-block.reject:disabled {
	background: #a4a8c5;
	border-color: #a4a8c5  !important;
}

.questions-btn-block.approve-yes {
	height: 40px;
	width: 88px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
}

.questions-btn-block.approve-yes:disabled {
	background: #a4a8c5;
	border-color: #a4a8c5  !important;
}

.questions-btn-outline,
.questions-btn-outline:hover,
.questions-btn-outline:focus,
.questions-btn-outline:visited {
	font-family: 'Montserrat';
	padding: 8px 28px;
	gap: 10px;
	background: #FFFFFF;
	border: 1px solid #2B3789;
	border-radius: 8px;
	margin-right: 10px;
	color: #2B3789;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
}

.similar-ques-container {
	margin: 2rem 0rem;
	background-color: #ffffff;
	padding: 20px;
	gap: 14px;
	border-radius: 8px;
	font-family: 'Montserrat';
	color: #595959;
	font-weight: 500;
	font-size: 18px;
}

.similar-ques,
.similar-ques:hover {
	background: #F8F8F8;
	border-radius: 8px;
	padding: 10px 15px 10px 10px;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	cursor: pointer;
}

.q-id-similar {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.015em;
	color: #595959;
	font-family: 'Montserrat';
	padding: 2px 10px;
	gap: 2px;
	background: #E7E7E7;
	border-radius: 16px;
	max-width: 48%;
	text-align: center;
	align-self: center;
}

.similar-ques-left-cont {
	width: 60%;
}

.similar-ques-right-cont {
	width: 38%;
	text-align: right;
	align-self: center;
}

.similar-ques-left-cont {
	display: flex;
}

.visited-div {
	color: #1F3061;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin-left: 15px;
	align-self: center;
	text-align: center;
}

.similarity-perc {
	padding: 2px 8px;
	gap: 10px;
	background: #FFF3F3;
	border-radius: 65px;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #D00A0A;
	margin-right: 15px;
}

.question-properties-container {
	margin: 2rem 0rem;
	background-color: #ffffff;
	padding: 20px;
	gap: 14px;
	border-radius: 8px;
	font-family: 'Montserrat';
	color: #595959;
	font-weight: 500;
	font-size: 18px;
}

.ques-prop-head {
	display: flex;
	justify-content: space-between;
	font-weight: 500;
	color: #393939;
}

.ques_prop-tags {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.ques_prop-tags .tag-name-ques-prop {
	flex-shrink: 0;
	margin-bottom: 8px;
}

.ques-prop-empty {
}

.edit-properties-button, .edit-properties-button:hover{
	background: #F5F8FF;
	border-radius: 45px;
	padding: 4px 10px;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	color: #1B3687;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.ques-prop-2 {
	font-weight: 500;
	font-size: 20px;
	line-height: 18px;
	color: #929292;
}

.ques-prop-input {
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	color: #929292;
}

.ques-prop:not(:last-child) {
	margin-bottom: 20px;
}

.ques-prop-label-required {
	color: #ff0022;
	font-weight: 600;
}

.feature_type-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.feature_type-option {
	padding: 4px 8px;
	border-radius: 40px;
	height: 25px;
	line-height: 1.25;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #6F6E6E;
	background: #F1F1F1;
}

.feature_type-option.selected {
	background: #1B3687;
	color: #fff;
}

.feature_type-option:not(:last-child) {
	margin-right: 12px;
}

.tag-name-ques-prop {
	padding: 2px 8px;
	background: #F1F1F1;
	border-radius: 25px;
	font-weight: 400;
	font-size: 19px;
	color: #6F6E6E;
	gap: 2px;
	max-width: 100%;
}

.tag-name-ques-prop:not(:last-child) {
	margin-right: 0.3rem;
}

.diff-level-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.diff-level{
	padding: 0px 8px;
	border: 0.8px solid #DBDBDB;
	border-radius: 50%;
	font-weight: 500;
	font-size: 19.6364px;
	color: #BFBFBF;
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.diff-level:not(:last-child) {
	margin-right: 8px;
}

.diff-level.disabled {
	cursor: default;
	min-width: 24px;
	min-height: 24px;
	width: 24px;
	height: 24px;
}

.diff-level.disabled.selected{
	border: 1px solid #1B3687;
	font-size: 24.5455px;
	min-height: 30px;
	min-width: 30px;
	height: 30px;
	width: 30px;
	color: #1B3687;
}

.diff-level-input {
	background: #F1F1F1;
	min-width: 30px;
	min-height: 30px;
	max-width: 30px;
	max-height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	color: #929292;
	cursor: pointer;
	user-select: none;
}

.diff-level-input:not(:last-child) {
	margin-right: 12px;
}

.diff-level-input.selected {
	background: #1B3687;
	color: #fff;
}

button.ques-prop-button {
	height: 32px;
	width: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background: #2B3789;
	color: #fff;
	outline: none;
	border: none;
}

.ques-prop-changes {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 8px;
	margin-bottom: 14px;
}

.ques-prop-changes span {
	margin-left: 4px;
	font-size: 16px;
	line-height: 1;
	color: #1B3687;
}

.questions-box-bordered{
	border: 1px solid #BDBDBD;
	border-radius: 8px;
	padding: 8px;
	gap: 12px;
	margin: 2rem 0rem;
	font-family: 'Montserrat';
	font-size: 16px;
}

.question-comparison{
	background-color: #ffffff;
	padding: 10px;
	gap: 12px;
	border-radius: 8px;
}

.question-details-comparison {
	background-color: #ffffff;
	align-items: flex-start;
	padding: 20px;
	gap: 14px;
	border-radius: 8px;
	margin-bottom: 12px;
}

.question-details-comparison-2 {
	background-color: #ffffff;
	align-items: flex-start;
	padding: 20px;
	gap: 14px;
	border-radius: 8px;
	margin-top: 12px;
}

.to-be-verified{
	margin-top: 10px;
	padding: 2px 6px;
	gap: 10px;
	background: #FFDEBF;
	border-radius: 47px;
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.015em;
	color: #151516;
}

.similar-ques-tag{
	align-self: center;
	padding: 2px 6px;
	gap: 10px;
	background: #E8E8E8;
	border-radius: 47px;
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.015em;
	color: #151516;
}

.option-row{
	display: flex;
	justify-content: space-between;
}

.option-col{
	width: 49%;
}

.option-col-full{
	width: 100%;
}

.option-num-bold {
	color: #595959;
	font-weight: 600;
	letter-spacing: 0.005em;
}

.bottom-bar {
	background: #FFFFFF;
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 30px;
	position: sticky;
	bottom: 30px;
	font-family: 'Montserrat';
	font-weight: 600;
	font-size: 20px;
	color: #000000;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.prompt-text{
	align-self: center;
}

.buttons{
	display: flex;
	justify-content: space-between;
}

.no-btn-sticky{
	background: #FFFFFF;
	border: 1px solid #2B3789;
	border-radius: 8px;
	color: #2B3789;
	width: 100px;
	margin-inline: 20px;
	padding: 4px
}

.yes-btn-sticky {
	background: #2B3789;
	border: 1px solid #2B3789;
	border-radius: 8px;
	color: #ffffff;
	width: 100px;
	margin-inline: 20px;
	padding: 4px
}

.modal-ques-prop-header {
	display: flex;
	padding: 6px 0;
	justify-content: space-between;
}

.modal-ques-prop-heading{
	font-weight: 600;
	font-size: 20px;
	color: #595959;
	align-self: center;
}

.modal-ques-prop-close-btn{
	color: #595959;
	background-color: #ffffff;
	border: none;
	font-size: 20px;
	font-weight: 600;
	align-self: center;
}

.ques-prop-1{
	font-size: 18px;
	font-weight: 500;
	color: #000;
}

.ques-prop-label {
	font-size: 16px;
	margin-bottom: 6px;
	font-weight: 500;
	color: #000;
}

.ques-prop-last {
	padding-top: 2px;
}

@media (max-width: 767.98px) {
	.questions_container {
		display: block;
	}

	.teacher_unverified_questions_container {
		margin: 2rem 1.5rem;
	}

	.question-solution-container {
		width: 100%;
	}

	.right-panel {
		width: 100%;
	}

	.q-id-similar {
		max-width: 100%;
	}

	.questions-reject-button,
	.questions-reject-button:hover,
	.questions-reject-button:focus,
	.questions-reject-button:visited {
		font-size: 14px;
	}

	.questions-approve-btn,
	.questions-approve-btn:hover,
	.questions-approve-btn:focus,
	.questions-approve-btn:visited {
		font-size: 14px;
	}

	.option-row{
		display: block;
	}

	.option-col{
		width: 100%;
	}
}